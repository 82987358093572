export type TFormValue = number | string | boolean | undefined | null;

export enum FormSelectValue {
  PLACEHOLDER = "placeholder",
  NONE = "none",
}

export enum GettingStartedInputId {
  CLIENT_ID = "clientId",
  GIVEN_NAME = "givenName",
  FAMILY_NAME = "familyName",
  EMAIL = "email",
  COMPANY_NAME = "companyName",
  COMPANY_TYPE = "companyType",
  REPRESENTATIVE_NAME = "representativeName",
  REPRESENTATIVE_EMAIL = "representativeEmail",
  ADDRESS = "address",
  MORE_ADDRESS = "moreAddress",
  COUNTRY_NAME = "countryName",
  COUNTRY_CODE = "countryCode",
  CITY = "city",
  ZIP_CODE = "zipCode",
  TERMS_AND_CONDITIONS = "termsAndConditions",
}

export enum BusinessRequestInputId {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
  CLIENT = "client",
  PHONE = "phone",
  PHONE_COUNTRY_CODE = "phoneCountryCode",
  PHONE_COUNTRY_DIAL_CODE = "phoneCountryDialCode",
  COUNTRY_NAME = "countryName",
  COUNTRY_CODE = "countryCode",
  OFFER = "offer",
  COMPANY_NAME = "companyName",
  COMPANY_TYPE = "customerType",
  REQUEST_REASON = "requestReason",
  MESSAGE = "message",
}

export enum ReportIssueInputId {
  EMAIL = "email",
  CLIENT = "client",
  PRODUCT_FAMILY = "productFamily",
  PRODUCT = "product",
  TITLE = "title",
  REQUEST_URL = "requestUrl",
  RESPONSE_CODE = "responseCode",
  CORRELATION_ID = "correlationId",
  DESCRIPTION = "description",
}

export enum SubscriptionRequestInputId {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
  PHONE = "phone",
  PHONE_COUNTRY_CODE = "phoneCountryCode",
  PHONE_COUNTRY_DIAL_CODE = "phoneCountryDialCode",
  COMPANY_NAME = "companyName",
  COMPANY_TYPE = "companyType",
  REPRESENTATIVE_NAME = "representativeName",
  REPRESENTATIVE_EMAIL = "representativeEmail",
  ADDRESS = "address",
  MORE_ADDRESS = "moreAddress",
  COUNTRY_NAME = "countryName",
  COUNTRY_CODE = "countryCode",
  CITY = "city",
  ZIP_CODE = "zipCode",
  BILLING_REPRESENTATIVE_NAME = "billingRepresentativeName",
  BILLING_REPRESENTATIVE_EMAIL = "billingRepresentativeEmail",
  VAT_NUMBER = "vatNumber",
  BILLING_ADDRESS = "billingAddress",
  BILLING_MORE_ADDRESS = "billingMoreAddress",
  BILLING_COUNTRY_NAME = "billingCountryName",
  BILLING_COUNTRY_CODE = "billingCountryCode",
  BILLING_CITY = "billingCity",
  BILLING_ZIP_CODE = "billingZipCode",
}

export enum NewCustomerInputId {
  COMPANY_NAME = "companyName",
  REPRESENTATIVE_NAME = "representativeName",
  REPRESENTATIVE_EMAIL = "representativeEmail",
  ENRICH_PARTNER = "enrichPartner",
}

export enum AddNewUser{
  GIVEN_NAME = "givenName",
  FAMILY_NAME="familyName",
  Email_Address="emailAddress",
  isPrimaryUser = "isPrimaryUser"
}
export enum UpdateWebhook{
  LABEL = "label",
  CLIENT_ID ="client_id",
  WEBHOOK_URL ="webhookUrl",
  CLIENT_SECRET ="clientSecret"
}
export enum AddNewContact{
  NAME = "name",
  Email_Address="emailAddress",
  isPrimary = "isPrimary",
  CONTACT_TYPE = "contactType"
}

export type TFormInputId =
  | GettingStartedInputId
  | BusinessRequestInputId
  | ReportIssueInputId
  | SubscriptionRequestInputId
  | NewCustomerInputId
  | AddNewUser
  | AddNewContact
  | UpdateWebhook;

export enum EFieldType {
  TEXT,
  TEXTAREA,
  EMAIL,
  PHONE,
  CHECKBOX,
  SELECT,
  COUNTRY,
  AUTOCOMPLETE,
  PASSWORD,
  VISIBLEPASSWORD
}

export enum EFormError {
  FIELD_REQUIRED = "Field should not be empty",
  EMAIL_INVALID = "Email format is invalid",
  PHONE_REQUIRED = "Phone is required",
  PHONE_INVALID = "Phone format is invalid",
  NOT_CHECKED = "Not checked",
  NO_OPTION_SELECTED = "No option selected",
  INJECTION = "Don't try to inject things. Check your input for < or > characters",
  LATIN_CHARACTERS="Only latin characters allowed"
}
