import HttpUtils from "../../../../utils/http.utils";
import { User } from "../../../authentication/models/user.model";
import { ClientSecretRequest } from "../models/clientSecretRequest.model";
import { Contact } from "../models/contact.model";
import encrypt  from "../../../../utils/encryption";
import { SubscriberConfiguration } from "../models/subscriberConfiguration.model";
import { ApiCredentialsPart } from "../enums/profilePart.enum";

export default class ClientSettingsAPI {
  public static getMatchedUsersForClient(): Promise<User[]> {
    
    return HttpUtils.get("/user/match");
  }
  public static getContactsByClient(): Promise<Contact[]> {
    return HttpUtils.get("/user/contact");
  }

  public static updateContactsByClient(clientId:string,contactList:any){
    const body = {
      clientId: clientId, // Set clientId to whatever value you need
      body: contactList      // Initialize an empty array for the body
    };
    return HttpUtils.put("/user/contact",body);
  }
  
  public static getApiCode(): Promise<string>{
    return HttpUtils.get("/user/code");
  }
  public static getClientSecretRequest(body: ClientSecretRequest): Promise<ClientSecretRequest>{
    const cipherText = encodeURIComponent(encrypt(body.clientId));   
    return HttpUtils.get(`/user/clientSecretRequest/${cipherText}/${body.validationCode}`);
  }
  public static getClientSecretRequests(): Promise<ClientSecretRequest[]>{
    return HttpUtils.get(`/user/clientSecretRequests`);
  }
  public static initiateClientSecretRequest(body: ClientSecretRequest): Promise<ClientSecretRequest>{    
    return HttpUtils.post(`/user/clientSecretRequest`, body);
  }
  public static resetClientSecretActivationCode(body: ClientSecretRequest): Promise<string>{ 
    const cipherText = encodeURIComponent(encrypt(body.clientId));    
    return HttpUtils.patch(`/user/clientSecretRequest/${cipherText}/activationCode`,{});
  }
  public static resetClientSecretRequest (body: ClientSecretRequest): Promise<ClientSecretRequest>{   
    const cipherText = encodeURIComponent(encrypt(body.clientId));   
    return HttpUtils.patch(`/user/clientSecretRequest/${cipherText}`,{resetSecretOnVault:body.status === ApiCredentialsPart.RESET_SECRET_ON_VAULT});
  }
  public static getSubscriberConfiguration():Promise<SubscriberConfiguration>{
    return HttpUtils.get(`/subscriberConfigurations/getSubscriberConfiguration`);
  }
  public static updateSubscriberConfiguration(body:any):Promise<void>{
    return HttpUtils.put(`/subscriberConfigurations/update`,body);
  }
}
