import React, { lazy, Suspense } from "react";
import { FormattedMessage } from "react-intl";

import {
  BusinessRequestInputId,
  EFieldType,
  EFormError,
  TFormInputId,
} from "../modules/common/submodules/form/enums/form.enum";
import { FormField } from "../modules/common/submodules/form/models/form-field.model";
import SelectInput from "../modules/common/submodules/form/components/selectInput";
import CountryInput from "../modules/common/submodules/form/components/countryInput";
import CheckboxInput from "../modules/common/submodules/form/components/checkboxInput";
import TextInput from "../modules/common/submodules/form/components/textInput";
import Loader from "../modules/common/components/loader";
import { IInputAdditionalParams } from "../modules/common/submodules/form/interfaces/form.interface";
import TextareaInput from "../modules/common/submodules/form/components/textareaInput";
import AutocompleteInput from "../modules/common/submodules/form/components/autocompleteInput";
import { IconButton, InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const CustomPhoneInput = lazy(
  () => import("../modules/common/submodules/form/components/phoneInput")
);

export default class FormUtils {
  static getInput(
    field: FormField,
    onChange: (
      id: TFormInputId,
      value: string | boolean,
      additionalParams?: IInputAdditionalParams
    ) => void,
    onBlur: (id: TFormInputId) => void,
    disabled = false,
    additionalParams: IInputAdditionalParams = {},
    handleClickShowPassword?:()=>void,
  ) {
    switch (field.type) {
      case EFieldType.SELECT:
        if (additionalParams.selectItems !== undefined) {
          return (
            <SelectInput
              id={field.id}
              value={field.value as string}
              options={field.options}
              disabled={disabled}
              items={additionalParams.selectItems}
              onChange={(value: string) => onChange(field.id, value)}
              onBlur={() => onBlur(field.id)}
            />
          );
        }

        return null;

      case EFieldType.COUNTRY:
        if (additionalParams.countryCode !== undefined) {
          return (
            <CountryInput
              id={field.id}
              countryName={field.value as string}
              countryCode={additionalParams.countryCode}
              options={field.options}
              onChange={(countryName: string, countryCode: string) =>
                onChange(field.id, countryName, { countryCode })
              }
              onBlur={() => onBlur(field.id)}
            />
          );
        }

        return null;

      case EFieldType.AUTOCOMPLETE:
        if (
          additionalParams.selectItems !== undefined &&
          additionalParams.onSearch !== undefined
        ) {
          return (
            <AutocompleteInput
              id={field.id}
              value={field.value as string}
              options={field.options}
              items={additionalParams.selectItems}
              isSearchLoading={additionalParams.isSearchLoading}
              onSearch={additionalParams.onSearch}
              onChange={(value: string) =>
                onChange(field.id, value, additionalParams)
              }
              onBlur={() => onBlur(field.id)}
            />
          );
        }

        return null;

      case EFieldType.PHONE:
        if (
          additionalParams.phoneCountryCode !== undefined &&
          additionalParams.phoneCountryDialCode !== undefined
        ) {
          return (
            <Suspense fallback={<Loader />}>
              <CustomPhoneInput
                id={field.id}
                phone={field.value as string}
                phoneCountryCode={additionalParams.phoneCountryCode}
                phoneCountryDialCode={additionalParams.phoneCountryDialCode}
                options={field.options}
                onChange={(
                  phone: string,
                  phoneCountryCode: string,
                  phoneCountryDialCode: number
                ) =>
                  onChange(BusinessRequestInputId.PHONE, phone, {
                    phoneCountryCode,
                    phoneCountryDialCode,
                  })
                }
                onBlur={() => onBlur(field.id)}
              />
            </Suspense>
          );
        }

        return null;

      case EFieldType.CHECKBOX:
        return (
          <CheckboxInput
            id={field.id}
            value={field.value}
            options={field.options}
            onChange={(value: boolean) => onChange(field.id, value)}
            onBlur={() => onBlur(field.id)}
          >
            {additionalParams && additionalParams.checkBoxContent}
          </CheckboxInput>
        );

      case EFieldType.TEXTAREA:
        return (
          <TextareaInput
            id={field.id}
            value={field.value as string}
            options={field.options}
            onChange={(value: string) => onChange(field.id, value)}
            onBlur={() => onBlur(field.id)}
          />
        );
      case EFieldType.PASSWORD:
        return (
          <TextInput
            id={field.id}
            value={field.value as string}
            options={field.options}
            type={EFieldType.TEXT}
            onChange={(value: string) => onChange(field.id, value)}
            onBlur={() => onBlur(field.id)}
            className="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        );
        case EFieldType.VISIBLEPASSWORD:
        return (
          <TextInput
            id={field.id}
            value={field.value as string}
            options={field.options}
            type={EFieldType.PASSWORD}
            onChange={(value: string) => onChange(field.id, value)}
            onBlur={() => onBlur(field.id)}
            className="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    <VisibilityOff />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        );
      default:
        return (
          <TextInput
            id={field.id}
            value={field.value as string}
            type={field.type}
            options={field.options}
            disabled={additionalParams.disabled??disabled}
            onChange={(value: string) => onChange(field.id, value)}
            onBlur={() => onBlur(field.id)}
          />
        );
    }
  }

  static getMessageFromError(message: EFormError): JSX.Element {
    switch (message) {
      case EFormError.FIELD_REQUIRED:
        return <FormattedMessage id="form.error.fieldRequired" />;

      case EFormError.EMAIL_INVALID:
        return <FormattedMessage id="form.error.emailInvalid" />;

      case EFormError.PHONE_REQUIRED:
        return <FormattedMessage id="form.error.phoneRequired" />;

      case EFormError.PHONE_INVALID:
        return <FormattedMessage id="form.error.phoneInvalid" />;

      case EFormError.NOT_CHECKED:
        return <FormattedMessage id="form.error.termsAndConditionsNotAgreed" />;

      case EFormError.INJECTION:
        return <FormattedMessage id="form.error.injection" />;

      case EFormError.NO_OPTION_SELECTED:
        return <FormattedMessage id="form.error.noOptionSelected" />;
      case EFormError.LATIN_CHARACTERS:
        return<FormattedMessage id="form.error.latinCharacters" />;

      default:
        return <></>;
    }
  }

  static isValueEmpty(value: string): boolean {
    return value === "";
  }
}
