import { authenticationType } from "../constants/authenticationType.constant";

export class OAuth2{
  authUrl:string;
  clientId:string;
  clientSecret:string;
  scope:string;
  constructor(body?:any){
    this.authUrl=body.authUrl;
    this.clientId=body.clientId;
    this.clientSecret=body.clientSecret;
    this.scope=body.scope;
  }
}
export class ApiKey{
  apiKeyHeaderName:string;
  apiKeyValue:string;
  constructor(body?:any){
    this.apiKeyHeaderName=body.apiKeyHeaderName;
    this.apiKeyValue=body.apiKeyValue;
  }
}
export class Basic{
  password:string;
  username:string;
  constructor(body?:any){
    this.username=body.username;
    this.password=body.password;
  }
}
export class DeliveryChannel{
  endpoint:string;
  authenticationType:string;
  authenticationDetail:ApiKey | OAuth2 | Basic | undefined;
  default:boolean;
  mapper:string[];
  index:string;
  constructor(body?: any) {
    this.authenticationType = body?.authenticationType || "";
    this.default = body?.default;
    this.index=body?.index;
    this.mapper = body?.mapper;
    this.endpoint = body?.endpoint;
    if (body?.authenticationDetail) {
      switch (body.authenticationType) {
        case authenticationType.OAUTH:
          this.authenticationDetail = new OAuth2(body.authenticationDetail);
          break;
        case authenticationType.APIKEY:
          this.authenticationDetail = new ApiKey(body.authenticationDetail);
          break;
        case authenticationType.BASIC_AUTH:
          this.authenticationDetail = new Basic(body.authenticationDetail);
          break;
        default:
          this.authenticationDetail = undefined;
      }
    } else {
      this.authenticationDetail = undefined;
    }
  }
}
export class SubscriberConfiguration {
    label: string;
    clientId: string;
    webhookUrl: string;
    clientSecret:string;
    deliveryChannels:DeliveryChannel[];
    constructor(body?: any) {
      this.label = body?.label || "";
      this.clientId = body?.clientId || "";
      this.webhookUrl = body?.webhookUrl || "";
      this.clientSecret = body?.clientSecret || "";
      this.deliveryChannels = (body?.deliveryChannels || []).map((channel: any) => new DeliveryChannel(channel));
    }
  }
  